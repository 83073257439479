.testimonial {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
  .testimonial-image {
    border-radius: 50%;
    max-width: 100px;
    margin: 0 auto 10px;
  }
  
  .testimonial-text {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .testimonial-author {
    font-style: italic;
  }
  