*,
*:after,
*:before {
  box-sizing: border-box;
}

ul {
  padding-left: 10px;
}

body {
  font-family: "Josefin Sans", sans-serif;
  color: #0a0a0a;
  line-height: 1.4;
}

a {
  color: #000;
}

img {
  width: 100%;
  border-radius: 8px 0 0 8px;
}

.item__image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item__title {
  font-size: 28px;
  font-weight: 600;
}

.icon {
  font-size: 16px;
  vertical-align: middle;
}

header {
  background-color: #f6f9fc;
  min-height: 500px;
  /*background-image: url("../images/pattern.png");*/
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0 80px 0;
  color: #246eea;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}

.container {
  width: 90%;
  margin: 0 auto;
}

.shadow {
  
  margin-top: 125px;
  /*background-image: url('./bcgrnd.jpg');
  background-repeat: no-repeat;
  background-size: cover;*/
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row {
  display: flex;
  width: 100%;
}

.txt {
  border-color: #e1e8ee;
  width: 100%;
}

.input {
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  height: 48px;
  padding-left: 15px;
  font-weight: 600;
  font-size: 14px;
  color: #5e6977;
}

input[type="text"] {
  display: initial;
  padding: 15px;
}

.text-validated {
  border-color: #7dc855;
  /*background-image: url("../images/icon-tick.png");*/
  background-repeat: no-repeat;
  background-position: right 18px center;
}

.ddl {
  border-color: #f0f4f7;
  background-color: #f0f4f7;
  width: 100px;
  margin-right: 10px;
}

.title {
  font-size: 14px;
  padding-bottom: 8px;
}

.field {
  padding-top: 15px;
  padding-right: 30px;
  width: 100%;
}
.field.small {
  width: auto;
}

.notification {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.notification .icon {
  font-size: 28px;
  color: #7dc855;
  line-height: 28px;
  margin-right: 10px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px 0 40px 0;
}


.payment__title {
  margin: 40px 0 20px 0;
  font-size: 18px;
  display: flex;
  text-align: left;
}
.payment__title .icon {
  margin-right: 10px;
}

.btn {
  font-family: "Josefin Sans", sans-serif;
  border-radius: 8px;
  border: 0;
  letter-spacing: 1px;
  color: #fff;
  background: #246eea;
  padding: 20px 60px;
  white-space: nowrap;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.15s ease;
  text-decoration: none;
}
.btn .icon {
  margin-left: 10px;
  font-size: 20px;
}
.btn:hover {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  background: #4984ea;
}
.btn.action__back {
  background: transparent;
  color: #a0a0a0;
}

.payment__types {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment__info {
  display: flex;
}

.payment__cc {
  flex: 60%;
}

.payment__shipping {
  flex: 40%;
}

.shipping__info {
  background: #f6f9fc;
  padding: 10px;
  border-radius: 8px;
}

.payment__type {
  display: flex;
  border: 2px solid #d9d9d9;
  border-radius: 8px;
  padding: 20px 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #d9d9d9;
  transition: all 0.15s ease;
}
.payment__type:hover {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.payment__type.active {
  color: #0a0a0a;
  background: #f6fcf7;
  border-color: #7dc855;
}
.payment__type .icon {
  font-size: 32px;
  margin-right: 10px;
}
.payment__type + .payment__type {
  margin-left: 10px;
}

.icon-xl {
  font-size: 48px;
  line-height: 48px;
}
.Gif-Area{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    border-radius: 25px;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
    border-radius: 25px;
    width: 60%;
}

/* Add mobile styles inside a media query */
@media (max-width: 767px) {
  .Gif-Area {
      flex-direction: column;
      text-align: center; /* Optional: Center the content */
  }

}


.loading {
  position: relative;
}
.loading:after {
  -webkit-animation: spinAround .5s infinite linear;
          animation: spinAround .5s infinite linear;
  border: 4px solid #7dc855;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  height: 5em;
  width: 5em;
  position: absolute;
}
.loading .details__item {
  opacity: 0;
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.centerAlign{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: 100%;
    margin: 2%;
    border-radius: 25px;
}

.info-container {
  
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  background-color: rgba(255,221,0,255);
  width: 40%;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999; /* To ensure it appears above other content */
  border-bottom-left-radius: 50%; /* Adjust the value as per your preference */
  border-bottom-right-radius: 50%;
}


/*
.info-container {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  background-color: #f8f8f8;
  width: 40%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
*/
.info-item {
  font-size: 16px;
  margin: 5px 0;
}

.videoShots {
  width: 100%;
}

/* For tablets (width greater than 768px and up to 1024px) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Gif-Area  {
    width: 80%;
  }
}

/* For phones (width less than 768px) */
@media only screen and (max-width: 767px) {
  .Gif-Area  {
    width: 100%;
  }
}

.playButtonBehind{
  border-radius: 25px;
  width: 100%;

}

.playButton{
  width: 80%;
  height: 45px;
  border-radius: 25px;
}

.playButton:hover{

  background-color: #7dc855;

}

.paymentBtn{
  margin-top: 25px;
  
}

.downloadButton {
  position: fixed;
  bottom: 0px; /* Adjust as needed to control the distance from the bottom */
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid #ccc;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  padding: 10px;
  background-color: rgba(255,221,0,255);
  width: 80%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999; /* To ensure it appears above other content */
}

.downloadButton:hover{
  background-color: #ffc107;
}

.alignCenter{
  text-align: center;
  margin-bottom: -100px;
}


.purchaseContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

