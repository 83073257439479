.containerCount{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: -15px;
    
}
.available{
    color: green;
}

.countdown{
    /*font-size: 35px;*/
}