/* Upload.css */
.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  
  .upload-form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .upload-form label {
    font-size: 18px;
  }
  
  .upload-form input[type="file"],
  .upload-form select,
  .upload-form input[type="tel"],
  .upload-form button {
    width: 95%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }



  .form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none; /* Remove input outline */
  }

  .upload-form button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .upload-form button:hover {
    background-color: #0056b3;
  }
  
  /* Center the form on the page */
  .upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Responsive layout for smaller screens */
  @media (max-width: 768px) {
    .upload-form {
      width: 90%;
      max-width: 400px;
    }
  }
  

  

  .submit-button {
    background-color: #007bff; /* Background color of the button */
    color: white; /* Text color of the button */
    border: none; /* Remove button border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 20px; /* Padding inside the button */
    font-size: 16px; /* Font size of the text */
    cursor: pointer; /* Show a pointer cursor on hover */
    transition: background-color 0.2s; /* Smooth transition for background color */
  
    /* Optional: Add a hover effect to change the background color on hover */
  }
  
  .submit-button:hover {
    background-color: #0056b3; /* New background color on hover */
  }