/* Stat.css */

.stat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .stat-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }
  
  .stat-form-label {
    margin: 10px 0;
    font-weight: bold;
  }
  
  .stat-form-input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    outline: none;
    transition: border-color 0.2s;
  }
  
  .stat-form-input:focus {
    border-color: #007bff;
  }
  
  /* Style the select dropdown separately */
  .stat-form-input.select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('arrow-down.svg'); /* Add your own arrow-down image */
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 30px; /* Adjust as needed based on your arrow icon size */
    cursor: pointer;
  }
  
  /* Style the submit button if needed */
  .submit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  