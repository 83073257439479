/* CharityCard.css */
.charity-card {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    text-align: center;
    border-radius: 25px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    width: 60%;
  }
  @media (max-width: 767px) {
    .charity-image-divider {
        flex-direction: column;
    }
    .charity-image {
      width: 95%;
      margin: 10px;
    }
    .charity-item{
      width: 95%;
      margin: 10px;
    }

}
  .charity-image {
    width: 90%;
    margin: 10px;
  }
  
  .charity-image-divider{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    flex-direction: column;
  }

  .charity-item{
    width: 100%;
    margin: 10px;
  }
  
  /* For tablets (width greater than 768px and up to 1024px) */
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .charity-card {
      width: 80%;
    }
  }
  
  /* For phones (width less than 768px) */
  @media only screen and (max-width: 767px) {
    .charity-card {
      width: 100%;
    }
  }