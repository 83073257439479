/* Style for FAQ Container */
.faq-container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Style for FAQ Item */
  .faq-item {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Style for FAQ Question */
  .faq-question {
    padding: 10px;
    background-color: #f9f9f9;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  
  /* Style for Toggle Icon */
  .toggle-icon {
    font-size: 20px;
  }
  
  /* Style for FAQ Answer */
  .faq-answer {
    padding: 10px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .faq-container {
      max-width: 100%;
      padding: 0 10px;
    }
  }
  
  .FAQContainer{
    width: 60%;
  }


  /* For tablets (width greater than 768px and up to 1024px) */
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .FAQContainer {
      width: 80%;
    }
  }
  
  /* For phones (width less than 768px) */
  @media only screen and (max-width: 767px) {
    .FAQContainer {
      width: 100%;
    }
  }